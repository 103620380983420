import { useMutation, useQuery } from '@apollo/client';
import {
	RecentSearchesQueryQuery,
	SearchSuggestionsQueryQuery,
	SuggestedUsersQueryQuery,
	TopColorsQueryQuery,
	TopSearchQueriesQueryQuery,
} from '@apps/www/src/__generated__/graphql';
import useIsLoggedIn from '@apps/www/src/www/hooks/useIsLoggedIn';
import useUIState, { UIStateKeys } from '@apps/www/src/www/hooks/useUIState';
import DeleteRecentSearchMutation from '@apps/www/src/www/queries/DeleteRecentSearchMutation';
import RecentSearchesQuery from '@apps/www/src/www/queries/RecentSearchesQuery';
import SearchSuggestionsQuery from '@apps/www/src/www/queries/SearchSuggestionsQuery';
import SuggestedUsersQuery from '@apps/www/src/www/queries/SuggestedUsersQuery';
import TopColorsQuery from '@apps/www/src/www/queries/TopColorsQuery';
import TopSearchQueriesQuery from '@apps/www/src/www/queries/TopSearchQueriesQuery';
import SVA from '@pkgs/shared-client/components/SVA';
import SVColorBalls from '@pkgs/shared-client/components/SVColorBalls';
import SVColorPickerOverlayContent from '@pkgs/shared-client/components/SVColorPickerOverlayContent';
import SVDropdown from '@pkgs/shared-client/components/SVDropdown';
import SVIconButton from '@pkgs/shared-client/components/SVIconButton';
import SVImage from '@pkgs/shared-client/components/SVImage';
import SVKeyboardKey from '@pkgs/shared-client/components/SVKeyboardKey';
import SVLink from '@pkgs/shared-client/components/SVLink';
import SVProBadge from '@pkgs/shared-client/components/SVProBadge';
import useEventCallback from '@pkgs/shared-client/hooks/useEventCallback';
import IconCloseSearchSVG from '@pkgs/shared-client/img/icon-close-search-inlined.svg';
import IconDeleteRecentSearchSVG from '@pkgs/shared-client/img/icon-delete-recent-search-inlined.svg';
import IconNavbarSearchSVG from '@pkgs/shared-client/img/navbar-search-icon-inlined.svg';
import ImagePalletColor from '@pkgs/shared-client/img/search-palet-color.png';
import { clsx } from 'clsx';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import { usePrevious } from 'react-use';
import { twMerge } from 'tailwind-merge';

type InputProps = {
	value: string;
	onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	onKeyDown: (e: React.KeyboardEvent<HTMLInputElement>) => void;
	isOnFocus: boolean;
	onFocus?: () => void;
	onClear: (e: React.UIEvent) => void;
	onSelectSuggestion?: (suggestion: string) => void;
	forceBlur?: () => void;
};

type UserSuggestion = ArrayElement<SearchSuggestionsQueryQuery['searchSuggestions']['users']> & {
	isUser: true;
};

const MIN_QUERY_LENGTH = 2 as const;
export const SUPPORTED_TYPES = ['items', 'users'] as const;

const PLACEHOLDER_CHANGE_INTERVAL = 3500; // 3.5s

const PLACEHOLDER_PHRASES = [
	'"design inspiration"',
	'"branding ideas"',
	'"typography"',
	'"#3730A3"',
];

const _AnimatedPlaceholderText = () => {
	const [currentIndex, setCurrentIndex] = useState(0);
	const lastIndex = usePrevious(currentIndex);

	useEffect(() => {
		const timer = setInterval(() => {
			setCurrentIndex((prevIndex) => (prevIndex + 1) % PLACEHOLDER_PHRASES.length);
		}, PLACEHOLDER_CHANGE_INTERVAL);

		return () => clearInterval(timer);
	}, [currentIndex]);

	return (
		<div className="absolute left-0 top-0 flex h-full w-full items-center pl-8 text-gray-500">
			<span>Search for&nbsp;</span>
			<span className="relative h-[28px] flex-1 overflow-visible">
				<span className="absolute h-full w-full overflow-hidden">
					{PLACEHOLDER_PHRASES.map((phrase, index) => (
						<span
							key={index}
							className={clsx(
								'absolute flex h-full w-full items-center',
								index === currentIndex
									? 'translate-y-0'
									: index === lastIndex
									? 'translate-y-[-28px]'
									: 'translate-y-[28px]',

								// Only animate current and previous phrase
								index === currentIndex || index === lastIndex
									? 'transition-all duration-[0.45s] ease-in-out'
									: '',
							)}
						>
							{phrase}
						</span>
					))}
				</span>
			</span>
		</div>
	);
};

const _Input = ({
	value,
	onChange,
	onKeyDown,
	isOnFocus,
	onClear,
	onFocus,
	onSelectSuggestion,
	onColorPickerOpen,
	isColorPickerOpen,
	...props
}: InputProps & { onColorPickerOpen?: (isOpen: boolean) => void; isColorPickerOpen?: boolean }) => {
	const inputRef = useRef<HTMLInputElement>(null);

	useEffect(() => {
		if (isOnFocus && inputRef.current) {
			inputRef.current.focus();
		}
	}, [isOnFocus]);

	const handleKeyDown = useEventCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		if (e.key === 'Enter') {
			e.preventDefault();
			onKeyDown(e);
			inputRef.current?.blur();
			return;
		}
		onKeyDown(e);
	});

	const handleFocus = useEventCallback(() => {
		onFocus?.();
	});

	const handleInputClick = useEventCallback(() => {
		if (isColorPickerOpen) {
			onColorPickerOpen?.(false);
		}
		onFocus?.();
	});

	return (
		<div
			className={twMerge(
				'duration-slide -md:hidden  text-primary border-separator relative z-10 flex h-[44px] w-[540px] max-w-[30vw] cursor-text space-x-2 rounded-full border-[1.5px] px-3 py-2.5 transition-all ease-in-out hover:border-gray-600',
				isOnFocus ? 'border-gray-500 hover:border-gray-500' : '',
			)}
			{...props}
		>
			<div className="duration-slide flex w-5 items-center justify-center text-gray-500 transition-all ease-in-out">
				<IconNavbarSearchSVG className="h-5 w-5 min-w-5" />
			</div>
			{!isOnFocus ? <_AnimatedPlaceholderText /> : null}
			<input
				type="text"
				value={value}
				onChange={onChange}
				onKeyDown={handleKeyDown}
				onFocus={handleFocus}
				onClick={handleInputClick}
				className="search-input-field w-full border-none bg-transparent p-0 text-xl outline-none focus:outline-none focus:ring-0"
				ref={inputRef}
				required
			/>
			<div
				className={clsx(
					'duration-over flex items-center justify-center space-x-2 transition-opacity ease-in-out',
					isOnFocus || value.length > 0 ? 'opacity-100' : 'pointer-events-none opacity-0',
				)}
			>
				<SVIconButton
					src={IconCloseSearchSVG}
					onClick={(e) => {
						e.stopPropagation();
						onClear(e);
					}}
					className="mr-1 h-5 w-5 text-gray-600"
					disableHover
				/>
			</div>
			<_ColorPickerButton
				isOnFocus={isOnFocus}
				value={value}
				onOpenChange={onColorPickerOpen}
			/>
		</div>
	);
};

const _ColorPickerButton = ({
	isOnFocus,
	value,
	onOpenChange,
}: {
	isOnFocus: boolean;
	value: string;
	onOpenChange?: (isOpen: boolean) => void;
}) => {
	const router = useRouter();

	const handleColorClick = useEventCallback((color: string) => {
		router.push(`/search?q=${encodeURIComponent(color)}`);
		onOpenChange?.(false);
	});

	return (
		<SVDropdown
			triggerType={SVDropdown.TRIGGER_TYPES.CLICK}
			positionStrategy={SVDropdown.POSITION_STRATEGIES.DYNAMIC}
			contentOffset="h-[20px]"
			onOpen={() => onOpenChange?.(true)}
			onClose={() => onOpenChange?.(false)}
			renderTrigger={({ isOpen, ...props }) => {
				return (
					<button
						className={clsx(
							'duration-over group relative flex min-w-5 cursor-pointer items-center justify-center',
							isOnFocus || value.length > 0
								? 'opacity-100'
								: 'pointer-events-none opacity-0',
						)}
						{...props}
					>
						<div className="z-10 flex h-5 w-5 items-center justify-center rounded-full bg-gray-200">
							<SVImage
								src={ImagePalletColor}
								alt="Search by color"
								className="h-5 w-5"
							/>
						</div>
						<div
							className={twMerge(
								'absolute flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-opacity-20 transition-all ease-in-out group-hover:bg-gray-300 group-hover:bg-opacity-20',
								isOpen && 'bg-gray-300',
							)}
						/>
					</button>
				);
			}}
			renderContent={() => (
				<div className="h-full min-h-[300px] w-full min-w-[300px]">
					<SVColorPickerOverlayContent
						onColorClick={handleColorClick}
						className="h-full w-full"
					/>
				</div>
			)}
		/>
	);
};

const _SectionTitle = ({ title, className }: { title: string; className?: string }) => (
	<div
		className={clsx(
			'flex items-center pb-3 text-xl text-gray-600 transition-opacity duration-75 ease-in',
			className,
		)}
	>
		{title}
	</div>
);

const _RecentSearchItem = ({
	search,
	handleClear,
}: {
	search: RecentSearchesQueryQuery['recentSearches'][number];
	handleClear: (id: RecentSearchesQueryQuery['recentSearches'][number]['_id']) => void;
}) => {
	const isHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(search.query);

	return (
		<SVLink
			className="duration-slide group mr-0.5 flex items-center rounded-full border border-gray-800 px-3 py-2 text-base transition-all ease-in-out hover:bg-gray-900"
			to={`/search?q=${encodeURIComponent(search.query)}`}
			title={`Search for ${search.query}`}
		>
			<div className="max-w-50 flex items-center space-x-2">
				{isHexColor && (
					<div
						className="h-5 w-5 min-w-5 rounded-full border border-gray-700"
						style={{ backgroundColor: search.query }}
					/>
				)}
				<div className="w-full truncate text-gray-400">{search.query}</div>
				<SVIconButton
					src={IconDeleteRecentSearchSVG}
					onClick={(e) => {
						handleClear(search._id);
						e.preventDefault();
						e.stopPropagation();
					}}
					className="text-gray-600 hover:text-gray-300"
					iconClassName="h-4 w-4 min-w-4 min-h-4"
					disableHover
					title="Delete recent search"
				/>
			</div>
		</SVLink>
	);
};

const _PopularSearchItem = ({
	search,
}: {
	search: TopSearchQueriesQueryQuery['topSearchQueries'][number];
}) => (
	<SVLink
		className="group flex h-14 w-[185px] items-center justify-start space-x-2 rounded-full border border-gray-700 px-2 py-2 transition-all ease-in-out hover:bg-gray-900"
		to={`/search?q=${encodeURIComponent(search.query)}`}
		title={`Search for ${search.query}`}
	>
		<div className="relative h-10 w-10 overflow-hidden rounded-full border border-transparent transition-all ease-in-out group-hover:border-gray-700">
			<div className="absolute inset-0 overflow-hidden">
				<div
					className="duration-over absolute inset-0 bg-cover bg-center bg-no-repeat opacity-100 transition-all ease-out"
					style={{
						backgroundColor: search.asset.colors[0]?.color,
						backgroundImage: `url('${search.asset.image.thumbnail}')`,
					}}
				/>
			</div>
		</div>

		<button className="w-fit truncate text-base text-gray-300">{search.query}</button>
	</SVLink>
);

const _TopUsersItem = ({ user }: { user: SuggestedUsersQueryQuery['suggestedUsers'][number] }) => (
	<SVA
		Component={SVLink}
		key={user._id}
		className="flex w-full min-w-[200px] items-center space-x-2 rounded-xl border border-transparent bg-gray-900 p-3 transition-all ease-in-out hover:border-gray-600"
		to={user.url}
		title={`View ${user.name}'s profile`}
	>
		<SVImage src={user.avatarURL} alt={user.name} className="h-8 w-8 rounded-full" />
		<div className="flex min-w-0 flex-1 flex-col">
			<div className="flex items-center">
				<div className="truncate whitespace-nowrap text-base text-gray-300">
					{user.name}
				</div>
				<SVProBadge className="text-primary ml-2" />
			</div>
			<div className="truncate whitespace-nowrap text-sm text-gray-600">@{user.username}</div>
		</div>
	</SVA>
);

const _SearchContent = ({
	recentSearches,
	popularSearches,
	isVisible,
	searchQuery,
	selectedIndex,
	onSelectSuggestion,
	onItemHover,
	hoveredItemIndex,
	searchSuggestions,
	topColors,
	topUsers,
}: {
	recentSearches: RecentSearchesQueryQuery['recentSearches'];
	popularSearches: TopSearchQueriesQueryQuery['topSearchQueries'];
	isVisible: boolean;
	searchQuery: string;
	selectedIndex: number;
	onSelectSuggestion: (suggestion: string | UserSuggestion) => void;
	onItemHover: (index: number | null) => void;
	hoveredItemIndex: number | null;
	searchSuggestions?: SearchSuggestionsQueryQuery['searchSuggestions'];
	topColors?: TopColorsQueryQuery['topColors'];
	topUsers?: SuggestedUsersQueryQuery['suggestedUsers'];
}) => {
	const [deleteRecentSearch] = useMutation(DeleteRecentSearchMutation, {
		update: (cache, { data }) => {
			if (!data?.deleteRecentSearch) return;

			const deletedID = data.deleteRecentSearch._id;

			// Update cache to remove the deleted search
			cache.modify({
				fields: {
					recentSearches: (existingSearches = []) => {
						return existingSearches.filter(
							(search: { __ref: string }) => !search.__ref.includes(deletedID),
						);
					},
				},
			});
		},
	});
	const contentRef = useRef<HTMLDivElement>(null);
	const [contentHeight, setContentHeight] = useState<number | undefined>(undefined);

	useEffect(() => {
		if (contentRef.current && isVisible) {
			const newHeight = contentRef.current.scrollHeight;
			setContentHeight(newHeight);
		}
	}, [isVisible, searchQuery, recentSearches, popularSearches, searchSuggestions]);

	const handleClearRecentSearches = useEventCallback(async (id: string) => {
		// Execute mutation
		await deleteRecentSearch({
			variables: {
				input: {
					searchID: id,
				},
			},
			optimisticResponse: {
				deleteRecentSearch: {
					__typename: 'RecentSearch',
					_id: id,
					query: '',
					deleted: true,
				},
			},
		});
	});

	const getTextSuggestions = () => {
		if (!searchSuggestions || searchQuery.trim().length === 0) return [searchQuery.trim()];

		// Get suggestions from the API
		const suggestions = searchSuggestions.queries.map((suggestion) => suggestion.query);

		// Only add the current query as first suggestion if it's not already in the list
		// and it has the minimum required length
		if (
			searchQuery.trim().length > MIN_QUERY_LENGTH &&
			!suggestions.includes(searchQuery.trim())
		) {
			return [searchQuery.trim(), ...suggestions];
		}

		return suggestions;
	};

	const userSuggestions: UserSuggestion[] =
		searchQuery.trim().length > 0 && searchSuggestions
			? searchSuggestions.users.map((user) => ({
					...user,
					isUser: true,
			  }))
			: [];

	const textSuggestions = getTextSuggestions();
	const allSuggestions = [...textSuggestions, ...userSuggestions];

	const isShowingSuggestions = searchQuery.trim().length > 0;

	if (!isVisible) {
		return null;
	}

	const hasContentToShow =
		recentSearches.length > 0 ||
		popularSearches?.length > 0 ||
		(topColors?.length ?? 0) > 0 ||
		(topUsers?.length ?? 0) > 0;

	return (
		<div
			className={twMerge(
				'duration-slide absolute left-1/2 top-full z-20 mt-2 w-[840px] max-w-[50vw] -translate-x-1/2 overflow-hidden rounded-[24px] border border-transparent bg-gray-950 transition-all ease-in-out lg:max-w-[70vw]',
				isVisible
					? 'translate-y-0 transform opacity-100'
					: 'pointer-events-none -translate-y-2 transform opacity-0',
				contentHeight !== undefined && contentHeight > 0 && 'border-gray-900',
			)}
			style={{
				height: contentHeight !== undefined ? `${contentHeight}px` : 'auto',
			}}
		>
			<div
				ref={contentRef}
				className={`transition-opacity duration-100 ease-in-out ${
					isVisible ? 'opacity-100' : 'opacity-0'
				}`}
			>
				{isShowingSuggestions ? (
					<div className="transition-opacity duration-100 ease-in-out">
						{allSuggestions.length > 0 ? (
							<div className="w-full space-y-0.5 p-3">
								{textSuggestions.map((suggestion, index) => {
									const globalIndex = index;
									return (
										<SearchSuggestionItem
											key={suggestion}
											suggestion={suggestion}
											searchQuery={searchQuery}
											isSelected={
												globalIndex === selectedIndex &&
												hoveredItemIndex === null
											}
											onClick={() => onSelectSuggestion(suggestion)}
											onMouseEnter={() => onItemHover(globalIndex)}
											onMouseLeave={() => onItemHover(null)}
											isHovered={hoveredItemIndex === globalIndex}
										/>
									);
								})}
								{userSuggestions.map((suggestion, index) => {
									const globalIndex = index + textSuggestions.length;
									return (
										<SearchSuggestionUser
											key={suggestion.username}
											suggestion={suggestion}
											isSelected={
												globalIndex === selectedIndex &&
												hoveredItemIndex === null
											}
											onClick={() => onSelectSuggestion(suggestion)}
											onMouseEnter={() => onItemHover(globalIndex)}
											onMouseLeave={() => onItemHover(null)}
											isHovered={hoveredItemIndex === globalIndex}
										/>
									);
								})}
							</div>
						) : null}
					</div>
				) : (
					<div
						className={clsx(
							hasContentToShow ? 'px-8 py-10' : '',
							'flex flex-col space-y-[60px] transition-opacity duration-100 ease-in-out',
						)}
					>
						{recentSearches.length > 0 && (
							<div className="-mr-8">
								<_SectionTitle title="Recents" />
								<div className="no-scrollbar flex gap-1 overflow-x-auto">
									{recentSearches.map((search) => (
										<_RecentSearchItem
											key={search._id}
											search={search}
											handleClear={handleClearRecentSearches}
										/>
									))}
								</div>
							</div>
						)}
						{popularSearches?.length > 0 && (
							<div>
								<_SectionTitle title="Popular" />
								<div className="no-scrollbar flex h-[138px] flex-col overflow-x-auto">
									<div className="mb-3 flex min-w-max gap-3">
										{popularSearches
											.slice(0, Math.ceil(popularSearches.length / 2))
											.map((popularSearch) => (
												<_PopularSearchItem
													key={popularSearch._id}
													search={popularSearch}
												/>
											))}
									</div>
									<div className="flex min-w-max gap-3">
										{popularSearches
											.slice(Math.ceil(popularSearches.length / 2))
											.map((popularSearch) => (
												<_PopularSearchItem
													key={popularSearch._id}
													search={popularSearch}
												/>
											))}
									</div>
								</div>
							</div>
						)}

						{(topUsers?.length ?? 0) > 0 && (
							<div>
								<_SectionTitle title="Trending users" />
								<div className="no-scrollbar -mr-8 flex space-x-6 overflow-x-auto">
									{topUsers?.map((user) => (
										<_TopUsersItem key={user._id} user={user} />
									))}
								</div>
							</div>
						)}

						{(topColors?.length ?? 0) > 0 && (
							<div>
								<_SectionTitle title="Trending colors" />
								<div className="no-scrollbar flex overflow-x-auto">
									<div className="flex min-w-max">
										<SVColorBalls
											colors={topColors || []}
											className="flex min-w-[calc(100%+100px)] space-x-2"
										/>
									</div>
								</div>
							</div>
						)}
					</div>
				)}
			</div>
		</div>
	);
};

const SearchSuggestionItem = ({
	suggestion,
	searchQuery,
	isSelected,
	onClick,
	onMouseEnter,
	onMouseLeave,
	isHovered,
}: {
	suggestion: string;
	searchQuery: string;
	isSelected?: boolean;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	isHovered?: boolean;
}) => {
	const query = searchQuery.toLowerCase();
	const suggestionLower = suggestion.toLowerCase();
	let index = -1;
	let matchLength = 0;
	const isHexColor = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(suggestion);

	if (query.endsWith(' ')) {
		const queryWithoutTrailingSpace = query.trimEnd();
		if (suggestionLower.startsWith(queryWithoutTrailingSpace + ' ')) {
			index = 0;
			matchLength = queryWithoutTrailingSpace.length + 1;
		}
	} else {
		index = suggestionLower.indexOf(query);
		matchLength = query.length;
	}

	// Apply styles based on selection or hover
	const isHighlighted = isSelected || isHovered;

	return (
		<SVA
			Component={SVLink}
			key={suggestion}
			className={clsx(
				'flex w-full items-center rounded-lg p-1 text-gray-500 transition-colors hover:bg-gray-900',
				isHighlighted && 'bg-gray-800 text-white',
			)}
			to={`/search?q=${encodeURIComponent(suggestion)}`}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			{isHexColor ? (
				<div
					className="ml-1.5 mr-3 h-4 w-4 min-w-4 rounded-full border border-gray-700"
					style={{ backgroundColor: suggestion }}
				/>
			) : (
				<IconNavbarSearchSVG className="ml-1.5 mr-3 h-4 w-4 text-gray-500" />
			)}
			{index === -1 ? (
				<span className="whitespace-pre">{suggestion}</span>
			) : (
				<div className="whitespace-pre">
					<span className={clsx('text-gray-500', isHighlighted && 'text-gray-300')}>
						{suggestion.substring(0, index)}
					</span>
					<span className={clsx('text-primary', isHighlighted && 'text-white')}>
						{suggestion.substring(index, index + matchLength)}
					</span>
					<span className={clsx('text-gray-500', isHighlighted && 'text-gray-300')}>
						{suggestion.substring(index + matchLength)}
					</span>
				</div>
			)}
		</SVA>
	);
};

const _BackgroundOverlay = ({ handleClick }: { handleClick?: () => void }) => (
	<div
		className="fixed inset-0 mt-[var(--nav-height)] min-h-screen cursor-pointer bg-black bg-opacity-50 transition-opacity duration-100 ease-in-out"
		onClick={(e) => {
			e.stopPropagation();
			handleClick?.();
		}}
	/>
);

const SearchSuggestionUser = ({
	suggestion,
	isSelected,
	onClick,
	onMouseEnter,
	onMouseLeave,
	isHovered,
}: {
	suggestion: UserSuggestion;
	isSelected?: boolean;
	onClick?: () => void;
	onMouseEnter?: () => void;
	onMouseLeave?: () => void;
	isHovered?: boolean;
}) => {
	// Apply styles based on selection or hover
	const isHighlighted = isSelected || isHovered;

	return (
		<SVA
			Component={SVLink}
			key={suggestion.username}
			className={clsx(
				'flex w-full items-center justify-between rounded-lg p-2 text-gray-300 transition-colors hover:bg-gray-900 hover:text-white',
				isHighlighted && 'bg-gray-800 text-white',
			)}
			to={suggestion.url}
			onClick={onClick}
			onMouseEnter={onMouseEnter}
			onMouseLeave={onMouseLeave}
		>
			<div className="flex items-center space-x-3">
				<SVImage
					src={suggestion.avatarURL}
					alt={suggestion.name}
					className="border-primary h-5 w-5 rounded-full border border-opacity-50"
				/>
				<div className="flex flex-col">
					<div className="text-sm ">{suggestion.name}</div>
					<div className="text-xs text-gray-500">@{suggestion.username}</div>
				</div>
			</div>
		</SVA>
	);
};

const _NavSearchContainer = () => {
	const router = useRouter();
	const [searchInputQuery, setSearchInputQuery] = useUIState(UIStateKeys.SEARCH_INPUT_QUERY);
	const { q: queryParam, type: typeParam } = router.query;
	const [isOnFocus, setIsOnFocus] = useState(false);
	const [isColorPickerOpen, setIsColorPickerOpen] = useState(false);
	const containerRef = useRef<HTMLDivElement>(null);
	const [selectedIndex, setSelectedIndex] = useState(-1);
	const [hoveredItemIndex, setHoveredItemIndex] = useState<number | null>(null);
	const [debouncedSearchQuery, setDebouncedSearchQuery] = useState('');
	const searchTimeoutRef = useRef<number | undefined>(undefined);
	const lastScrollY = useRef(0);

	const currentType =
		typeof typeParam === 'string' &&
		SUPPORTED_TYPES.includes(typeParam as (typeof SUPPORTED_TYPES)[number])
			? (typeParam as (typeof SUPPORTED_TYPES)[number])
			: 'items';

	// Recent searches query
	const recentSearchesQuery = useQuery(RecentSearchesQuery);

	//  Popular queries
	const topSearchQueriesQuery = useQuery(TopSearchQueriesQuery);

	// Top colors query
	const topColorsQuery = useQuery(TopColorsQuery);

	// Top users query
	const topUsersQuery = useQuery(SuggestedUsersQuery);

	// Search suggestions query with debouncing
	const searchSuggestionsQuery = useQuery(SearchSuggestionsQuery, {
		variables: {
			query: debouncedSearchQuery || '',
		},
		skip: !Boolean(debouncedSearchQuery),
	});

	// Get suggestions for keyboard navigation
	const getUserSuggestions = () => {
		if (!searchSuggestionsQuery.data?.searchSuggestions || !debouncedSearchQuery) return [];
		return searchSuggestionsQuery.data.searchSuggestions.users.map((user) => ({
			...user,
			isUser: true as const,
		}));
	};

	const getTextSuggestions = () => {
		if (!searchSuggestionsQuery.data?.searchSuggestions || !debouncedSearchQuery) return [];

		// Get suggestions from the API
		const suggestions = searchSuggestionsQuery.data.searchSuggestions.queries.map(
			(suggestion) => suggestion.query,
		);

		// Only add the current query as first suggestion if it's not already in the list
		// and it has the minimum required length
		if (
			searchInputQuery.trim().length > MIN_QUERY_LENGTH &&
			!suggestions.includes(searchInputQuery.trim())
		) {
			return [searchInputQuery.trim(), ...suggestions];
		}

		return suggestions;
	};

	const userSuggestions = getUserSuggestions();
	const textSuggestions = getTextSuggestions();
	const allSuggestions = [...textSuggestions, ...userSuggestions];

	useEffect(() => {
		if (router.pathname === '/search') {
			if (
				queryParam &&
				typeof queryParam === 'string' &&
				queryParam.length > MIN_QUERY_LENGTH
			) {
				setSearchInputQuery(queryParam);
				setIsOnFocus(false);
				return;
			}

			setIsOnFocus(true);
			return;
		}

		setSearchInputQuery('');
		setIsOnFocus(false);
	}, [queryParam, setSearchInputQuery, router.pathname]);

	useEffect(() => {
		// Reset selected index when input changes
		setSelectedIndex(-1);
		setHoveredItemIndex(null);

		// Set up debounce for search query
		if (searchTimeoutRef.current !== undefined) {
			window.clearTimeout(searchTimeoutRef.current);
		}

		// Only debounce if query is long enough
		if (searchInputQuery.trim().length > MIN_QUERY_LENGTH) {
			// @ts-ignore - window.setTimeout returns number in browser
			searchTimeoutRef.current = window.setTimeout(() => {
				setDebouncedSearchQuery(searchInputQuery.trim());
				searchTimeoutRef.current = undefined;
			}, 300);
		} else {
			setDebouncedSearchQuery('');
		}

		return () => {
			if (searchTimeoutRef.current !== undefined) {
				window.clearTimeout(searchTimeoutRef.current);
				searchTimeoutRef.current = undefined;
			}
		};
	}, [searchInputQuery]);

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			if (containerRef.current && !containerRef.current.contains(event.target as Node)) {
				setIsOnFocus(false);
			}
		};

		document.addEventListener('mousedown', handleClickOutside);
		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	const handleResetSearch = useEventCallback(() => {
		setIsOnFocus(false);
		setIsColorPickerOpen(false);
		setSearchInputQuery('');
		// Force blur on input
		setTimeout(() => {
			const inputElement = document.querySelector('.search-input-field') as HTMLInputElement;
			if (inputElement) {
				inputElement.blur();
			}
		}, 0);
	});

	// Add scroll event listener to close search when scrolling down
	useEffect(() => {
		const handleScroll = () => {
			const currentScrollY = window.scrollY;

			// Close search if it's open and user is scrolling down
			if (currentScrollY > lastScrollY.current && (isOnFocus || isColorPickerOpen)) {
				handleResetSearch();
			}

			lastScrollY.current = currentScrollY;
		};

		window.addEventListener('scroll', handleScroll, { passive: true });
		return () => window.removeEventListener('scroll', handleScroll);
	}, [isOnFocus, isColorPickerOpen, handleResetSearch]);

	const handleSearchChange = useEventCallback((e: React.ChangeEvent<HTMLInputElement>) => {
		setSearchInputQuery(e.target.value);
	});

	const navigateToSearch = useEventCallback((query: string) => {
		if (query.trim().length > MIN_QUERY_LENGTH) {
			setSearchInputQuery(query.trim());
			setDebouncedSearchQuery(query.trim());
			setIsOnFocus(false);

			router.replace(
				{
					pathname: '/search',
					query: {
						q: query.trim(),
						...(currentType !== 'items' && { type: currentType }),
					},
				},
				undefined,
				{ shallow: true },
			);
		} else if (router.pathname === '/search') {
			router.replace({
				pathname: '/search',
			});
		}
	});

	const handleSelectSuggestion = useEventCallback((suggestion: string | UserSuggestion) => {
		if (typeof suggestion === 'string') {
			navigateToSearch(suggestion);
		} else {
			// Navigate to user profile
			router.push(suggestion.url);
			setIsOnFocus(false);
		}
	});

	const handleKeyDown = useEventCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
		// Keyboard navigation logic
		if (allSuggestions.length > 0 && isOnFocus) {
			switch (e.key) {
				case 'ArrowDown':
					e.preventDefault();
					setSelectedIndex((prev) => (prev < allSuggestions.length - 1 ? prev + 1 : 0));
					setHoveredItemIndex(null);
					break;
				case 'ArrowUp':
					e.preventDefault();
					setSelectedIndex((prev) => (prev > 0 ? prev - 1 : allSuggestions.length - 1));
					setHoveredItemIndex(null);
					break;
				case 'Tab':
					e.preventDefault();
					if (selectedIndex >= 0 && selectedIndex < allSuggestions.length) {
						handleSelectSuggestion(allSuggestions[selectedIndex]);
					}
					break;
				case 'Enter':
					e.preventDefault();
					if (selectedIndex >= 0 && selectedIndex < allSuggestions.length) {
						handleSelectSuggestion(allSuggestions[selectedIndex]);
					} else {
						// Cancel any pending debounce timeout
						if (searchTimeoutRef.current !== undefined) {
							window.clearTimeout(searchTimeoutRef.current);
						}
						navigateToSearch(searchInputQuery);
					}
					break;
				case 'Escape':
					e.preventDefault();
					setIsOnFocus(false);
					break;
			}
		} else if (e.key === 'Enter') {
			e.preventDefault();
			// Cancel any pending debounce timeout
			if (searchTimeoutRef.current !== undefined) {
				window.clearTimeout(searchTimeoutRef.current);
			}
			navigateToSearch(searchInputQuery);
		}
	});

	const handleClearSearch = useEventCallback((e: React.UIEvent) => {
		if (e) {
			e.stopPropagation();
		}

		setSearchInputQuery('');

		if (router.pathname === '/search') {
			router.replace({
				pathname: '/explore',
				query: undefined,
			});
			return;
		}

		setIsOnFocus(false);
	});

	const handleContainerClick = useEventCallback(() => {
		if (isColorPickerOpen) {
			setIsColorPickerOpen(false);
		}
		setIsOnFocus(true);
	});

	const handleItemHover = useEventCallback((index: number | null) => {
		setHoveredItemIndex(index);
	});

	const handleColorPickerOpenChange = useEventCallback((isOpen: boolean) => {
		setIsColorPickerOpen(isOpen);
	});

	const handleOverlayClick = useEventCallback((e?: React.SyntheticEvent) => {
		if (e) {
			e.stopPropagation();
		}

		setIsOnFocus(false);
		setIsColorPickerOpen(false);

		document.activeElement instanceof HTMLElement && document.activeElement.blur();
	});

	// Only show content when we're focused AND the color picker is closed
	const shouldShowContent = isOnFocus && !isColorPickerOpen;

	return (
		<>
			<div
				ref={containerRef}
				onClick={handleContainerClick}
				className="-md:hidden relative z-20"
			>
				<_Input
					value={searchInputQuery}
					onChange={handleSearchChange}
					onKeyDown={handleKeyDown}
					isOnFocus={isOnFocus}
					onClear={(e) => handleClearSearch(e)}
					onFocus={() => setIsOnFocus(true)}
					onSelectSuggestion={(suggestion) => handleSelectSuggestion(suggestion)}
					onColorPickerOpen={handleColorPickerOpenChange}
					isColorPickerOpen={isColorPickerOpen}
				/>

				<_SearchContent
					recentSearches={recentSearchesQuery.data?.recentSearches || []}
					popularSearches={topSearchQueriesQuery.data?.topSearchQueries || []}
					isVisible={shouldShowContent}
					searchQuery={searchInputQuery}
					selectedIndex={selectedIndex}
					onSelectSuggestion={handleSelectSuggestion}
					onItemHover={handleItemHover}
					hoveredItemIndex={hoveredItemIndex}
					searchSuggestions={searchSuggestionsQuery.data?.searchSuggestions}
					topColors={topColorsQuery.data?.topColors}
					topUsers={topUsersQuery.data?.suggestedUsers}
				/>
				{(isColorPickerOpen || isOnFocus) && (
					<_BackgroundOverlay handleClick={handleOverlayClick} />
				)}
			</div>
			<SVKeyboardKey keys={['escape']} onTrigger={handleResetSearch} />
		</>
	);
};

const SVNavSearchContainer = () => {
	const isLoggedIn = useIsLoggedIn();

	if (!isLoggedIn) {
		return null;
	}

	return <_NavSearchContainer />;
};

export default SVNavSearchContainer;
